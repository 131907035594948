@import "app/styles/fonts";
@import "app/styles/colors";

.locationsCount {
  margin: 50px 0 0px 0;

  &:first-child {
    margin-top: 0;
  }
}

.locationCountDescription {
  font-size: 14px;
  line-height: 20px;
  color: $gray-text;
  margin-bottom: 20px;
}

.locationCountHeading {
  font-size: 18px;
  line-height: 25px;
  font-weight: $font-weight-semibold;
  color: $black;
  margin-bottom: 11px;
}
